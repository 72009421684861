.App-header {
  position: relative;
}

.App-title {
  font-size: 36px;
  padding: 20px 0;
  width: 100%;
  position: absolute;
  background-color: transparent;
  text-align: center;
}


.App-detail-title {
  font-family: Crimson Text;
  font-size: 24px;
  margin-bottom: 25px;
  text-align: left;
}

.App-detail:nth-child(1) .App-detail-title {
  text-align: center;
}

.App-detail-inner {
  max-width: 1100px;
  margin: auto;
  padding: 20px;
}

.App-detail-image {
  padding: 25px 100px;
  text-align: center;
}

.App-detail:nth-child(1) .App-detail-image {
  padding: 25px 0;
}

.App-detail-image img {
  width: 100%;
}

.App-details .Appstore-badges {
  padding: 0;
}

.App-email-form-box {
  background: #fefdfa;
}

.Header-info {
  font-size: 24px;
  background: url("../assets/landing-bg-mobile.svg");
  background-size: cover;
  padding-top: 75px;
}
.App-email-form-box {
  display: block;
}
.App-email-form-in-header {
  display: none;
}

.App-info-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-slogan {
  width: 231px;
}

.emphasis {
  font-style: italic;
}

.App-email-form input {
  background: transparent;
  border: 0;
  padding: 0;
  font-family: Crimson Text;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  flex-grow: 1;
}
.App-email-form input:focus {
  outline: none;
}

.App-email-form button {
  background: transparent;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 12px;
  padding-right: 0;
  cursor: pointer;
}

.App-email-form .inputs {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: stretch;
  border: 1px dashed #434651;
  max-width: 300px;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  /* line-height: 30px; */
  margin: 0 auto;
}
.App-email-form .inputs:focus-within {
  border: 1px dashed black;
}

.App-email-form .inputs.error {
  border: 1px dashed red;
}
.App-email-form .inputs.error input {
  color: red;
}

footer {
  margin-top: 35px;
  margin-bottom: 35px;
}

footer .App-email-form-box {
  background: #fef7e5;
  display: block;
}

footer .App-email-form-box-inner {
  max-width: 800px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
}
.App-email-form-cta {
  font-size: 18px;
  padding-bottom: 10px;
}

footer .App-email-form button {
  margin-top: 2px;
}

.Alice-quote {
  max-width: 500px;
  text-align: right;
  font-size: 24px;
  font-style: italic;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 50px 20px;
}
.Alice-quote > p {
  margin: 0;
}
.Alice-quote .Alice {
  text-align: left;
}
.Alice-quote .Flower {
  margin-top: 2px;
}
.Alice-quote .Attribution {
  margin-top: 4px;
  font-style: normal;
  font-size: 14px;
}

@media only screen and (min-width: 600px) {
  .Header-info {
    background: url("../assets/landing_image_3.svg");
    font-size: 24px;
    background-size: cover;
    padding-top: 75px;
  }

  .App-title {
    padding: 20px;
    text-align: left;
    width: calc(100% - 40px);
  }

  .App-info {
    font-size: 24px;
    height: 90vh;
    background-position: center;
    background-size: cover;
    padding-bottom: 0;
    position: relative;
  }

  .Header-info {
    padding-top: 0px;
  }

  .App-email-form-box {
    display: none;
  }
  .App-email-form-in-header {
    display: block;
  }
  .App-slogan {
    width: 380px;
    padding: 40px;
    font-size: 18px;
    line-height: 26px;
  }
  .Slogan-text {
    font-size: 36px;
    line-height: 50px;
  }
  .App-detail-inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    margin-bottom: 80px;
    padding: 0;
  }

  .App-detail:nth-child(1) .App-detail-title {
    text-align: left;
  }
  .App-detail-title {
    flex: 1 100%;
    margin-bottom: 20px;
    font-size: 36px;
    text-align: left;
  }
  .App-detail-info {
    margin-top: 0;
  }
  .App-detail-image {
    padding: 0;
    height: 70vw;
    max-height: 800px;
  }
  .App-detail-image img {
    width: auto;
    height: 100%;
    max-height: 800px;
  }
  .App-detail:nth-child(odd) .App-detail-inner {
    flex-direction: row-reverse;
  }
  .App-detail:nth-child(1) .App-detail-inner {
    padding-top: 20px;
  }
  .App-detail:nth-child(odd) .App-detail-info {
    padding-left: 8%;
    padding-right: 50px;
  }
  .App-detail:nth-child(even) .App-detail-info {
    padding-right: 8%;
    padding-left: 50px;
  }
  .App-detail:nth-child(odd) .App-detail-image {
    padding-left: 50px;
  }
  .App-detail:nth-child(even) .App-detail-image {
    padding-right: 50px;
  }
  footer .App-email-form-box-inner {
    font-size: 24px;
    flex-direction: row;
  }

  .Alice-quote {
    height: 90vh;
    padding: 0 50px;
  }

  footer .App-email-form-box {
      background-color: #fef7e5;
  }
}

footer .footer-links {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
}

footer .footer-links .link {
    display: inline-flex;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: #434651;
    font-weight: 600;
    align-items: center;
    text-transform: capitalize;
}

footer .footer-links .link:not(:last-child) {
    margin-right: 10px;
}

footer .footer-links .link:not(:last-child)::after {
    content:"";
    width: 1px;
    background-color: #434651;
    height: 23px;
    margin-left: 10px;
}

footer .footer-links .link a {
    text-decoration: none;
    color: inherit;
}

footer .footer-links .link svg {
    display: block;
}
