@import url(https://fonts.googleapis.com/css?family=Crimson+Text:400,400i,700|Lato|Montserrat:600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-header {
  position: relative;
}

.App-title {
  font-size: 36px;
  padding: 20px 0;
  width: 100%;
  position: absolute;
  background-color: transparent;
  text-align: center;
}


.App-detail-title {
  font-family: Crimson Text;
  font-size: 24px;
  margin-bottom: 25px;
  text-align: left;
}

.App-detail:nth-child(1) .App-detail-title {
  text-align: center;
}

.App-detail-inner {
  max-width: 1100px;
  margin: auto;
  padding: 20px;
}

.App-detail-image {
  padding: 25px 100px;
  text-align: center;
}

.App-detail:nth-child(1) .App-detail-image {
  padding: 25px 0;
}

.App-detail-image img {
  width: 100%;
}

.App-details .Appstore-badges {
  padding: 0;
}

.App-email-form-box {
  background: #fefdfa;
}

.Header-info {
  font-size: 24px;
  background: url(/static/media/landing-bg-mobile.bd82ba04.svg);
  background-size: cover;
  padding-top: 75px;
}
.App-email-form-box {
  display: block;
}
.App-email-form-in-header {
  display: none;
}

.App-info-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-slogan {
  width: 231px;
}

.emphasis {
  font-style: italic;
}

.App-email-form input {
  background: transparent;
  border: 0;
  padding: 0;
  font-family: Crimson Text;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  flex-grow: 1;
}
.App-email-form input:focus {
  outline: none;
}

.App-email-form button {
  background: transparent;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 12px;
  padding-right: 0;
  cursor: pointer;
}

.App-email-form .inputs {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: stretch;
  border: 1px dashed #434651;
  max-width: 300px;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  /* line-height: 30px; */
  margin: 0 auto;
}
.App-email-form .inputs:focus-within {
  border: 1px dashed black;
}

.App-email-form .inputs.error {
  border: 1px dashed red;
}
.App-email-form .inputs.error input {
  color: red;
}

footer {
  margin-top: 35px;
  margin-bottom: 35px;
}

footer .App-email-form-box {
  background: #fef7e5;
  display: block;
}

footer .App-email-form-box-inner {
  max-width: 800px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
}
.App-email-form-cta {
  font-size: 18px;
  padding-bottom: 10px;
}

footer .App-email-form button {
  margin-top: 2px;
}

.Alice-quote {
  max-width: 500px;
  text-align: right;
  font-size: 24px;
  font-style: italic;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 50px 20px;
}
.Alice-quote > p {
  margin: 0;
}
.Alice-quote .Alice {
  text-align: left;
}
.Alice-quote .Flower {
  margin-top: 2px;
}
.Alice-quote .Attribution {
  margin-top: 4px;
  font-style: normal;
  font-size: 14px;
}

@media only screen and (min-width: 600px) {
  .Header-info {
    background: url(/static/media/landing_image_3.4636c8c8.svg);
    font-size: 24px;
    background-size: cover;
    padding-top: 75px;
  }

  .App-title {
    padding: 20px;
    text-align: left;
    width: calc(100% - 40px);
  }

  .App-info {
    font-size: 24px;
    height: 90vh;
    background-position: center;
    background-size: cover;
    padding-bottom: 0;
    position: relative;
  }

  .Header-info {
    padding-top: 0px;
  }

  .App-email-form-box {
    display: none;
  }
  .App-email-form-in-header {
    display: block;
  }
  .App-slogan {
    width: 380px;
    padding: 40px;
    font-size: 18px;
    line-height: 26px;
  }
  .Slogan-text {
    font-size: 36px;
    line-height: 50px;
  }
  .App-detail-inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    margin-bottom: 80px;
    padding: 0;
  }

  .App-detail:nth-child(1) .App-detail-title {
    text-align: left;
  }
  .App-detail-title {
    flex: 1 1 100%;
    margin-bottom: 20px;
    font-size: 36px;
    text-align: left;
  }
  .App-detail-info {
    margin-top: 0;
  }
  .App-detail-image {
    padding: 0;
    height: 70vw;
    max-height: 800px;
  }
  .App-detail-image img {
    width: auto;
    height: 100%;
    max-height: 800px;
  }
  .App-detail:nth-child(odd) .App-detail-inner {
    flex-direction: row-reverse;
  }
  .App-detail:nth-child(1) .App-detail-inner {
    padding-top: 20px;
  }
  .App-detail:nth-child(odd) .App-detail-info {
    padding-left: 8%;
    padding-right: 50px;
  }
  .App-detail:nth-child(even) .App-detail-info {
    padding-right: 8%;
    padding-left: 50px;
  }
  .App-detail:nth-child(odd) .App-detail-image {
    padding-left: 50px;
  }
  .App-detail:nth-child(even) .App-detail-image {
    padding-right: 50px;
  }
  footer .App-email-form-box-inner {
    font-size: 24px;
    flex-direction: row;
  }

  .Alice-quote {
    height: 90vh;
    padding: 0 50px;
  }

  footer .App-email-form-box {
      background-color: #fef7e5;
  }
}

footer .footer-links {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
}

footer .footer-links .link {
    display: inline-flex;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: #434651;
    font-weight: 600;
    align-items: center;
    text-transform: capitalize;
}

footer .footer-links .link:not(:last-child) {
    margin-right: 10px;
}

footer .footer-links .link:not(:last-child)::after {
    content:"";
    width: 1px;
    background-color: #434651;
    height: 23px;
    margin-left: 10px;
}

footer .footer-links .link a {
    text-decoration: none;
    color: inherit;
}

footer .footer-links .link svg {
    display: block;
}

.username {
  text-transform: capitalize;
  font-style: italic;
}

.Invite-details > header {
  font-size: 36px;
  padding: 25px;
  text-align: center;
}

.Invite-details .Expiration {
  font-size: 18px;
}

.Invite-details .Expiration-count {
  font-style: italic;
}

.Invite-details > .Info {
  text-align: left;
}
.Invite-details > .Info p {
  margin: 0;
}

.Invite-details > .Info a,
.Invite-details > .Info a:visited {
  color: inherit;
}

.Invite-details .Header-info {
  display: none;
}

.Appstore-badges {
  width: 160px;
  margin: auto;
  padding: 50px;
  text-align: center;
}
.Appstore-badges a {
  display: block;
  color: #828282;
  font-size: 14px;
}
.Appstore-badges a:visited {
  color: #828282;
}
.Appstore-badges img {
  width: 100%;
}

.Appstore-badge {
  display: block;
}

@media only screen and (min-width: 600px) {
  .Appstore-badges {
    width: 100%;
    margin: 0;
    padding: 0px;
    text-align: left;
  }
  .Appstore-badge {
    display: inline-block;
    margin-right: 15px;
  }
  .Appstore-badges img {
    width: auto;
    width: initial;
    height: 50px;
  }
}

.divider-line {
  text-align: center;
}

.Invite-details > .App-details {
  margin-top: 50px;
}

body {
  background-color: #fefdfa;
}

.App {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #434651;
}

.Wither-box {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
  background: #fef7e5;
  border-top: 0.5px solid #bdbdbd;
  border-bottom: 0.5px solid #bdbdbd;
  padding: 25px;
}

.bouquet {
    height: 300px;
}

.bouquet .bouquet-flower {
    height: 300px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

