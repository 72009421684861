.username {
  text-transform: capitalize;
  font-style: italic;
}

.Invite-details > header {
  font-size: 36px;
  padding: 25px;
  text-align: center;
}

.Invite-details .Expiration {
  font-size: 18px;
}

.Invite-details .Expiration-count {
  font-style: italic;
}

.Invite-details > .Info {
  text-align: left;
}
.Invite-details > .Info p {
  margin: 0;
}

.Invite-details > .Info a,
.Invite-details > .Info a:visited {
  color: inherit;
}

.Invite-details .Header-info {
  display: none;
}

.Appstore-badges {
  width: 160px;
  margin: auto;
  padding: 50px;
  text-align: center;
}
.Appstore-badges a {
  display: block;
  color: #828282;
  font-size: 14px;
}
.Appstore-badges a:visited {
  color: #828282;
}
.Appstore-badges img {
  width: 100%;
}

.Appstore-badge {
  display: block;
}

@media only screen and (min-width: 600px) {
  .Appstore-badges {
    width: 100%;
    margin: 0;
    padding: 0px;
    text-align: left;
  }
  .Appstore-badge {
    display: inline-block;
    margin-right: 15px;
  }
  .Appstore-badges img {
    width: initial;
    height: 50px;
  }
}

.divider-line {
  text-align: center;
}

.Invite-details > .App-details {
  margin-top: 50px;
}
