.bouquet {
    height: 300px;
}

.bouquet .bouquet-flower {
    height: 300px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
