@import url("https://fonts.googleapis.com/css?family=Crimson+Text:400,400i,700|Lato|Montserrat:600&display=swap");
@import "Landing.css";
@import "Invite.css";

body {
  background-color: #fefdfa;
}

.App {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #434651;
}

.Wither-box {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
  background: #fef7e5;
  border-top: 0.5px solid #bdbdbd;
  border-bottom: 0.5px solid #bdbdbd;
  padding: 25px;
}
